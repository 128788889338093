//banner start

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    justify-content: center;
  }

  .kiko-logo {
    max-width: 250px;
  }

  .header-title {
    font-size: 40px;
    font-weight: 700;
    color: #0e75bd;
  }
}

.NewbannerSection {
  .leftImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .slick-dots li button:before {
    font-size: 11px;
  }

  .slick-dots li button:hover::before {
    color: #117c57;
  }

  .slick-dots li.slick-active button:before {
    color: #117c57;
  }

  .rightSection {
    @media screen and (max-width: 992px) {
      text-align: center;
      margin-top: 40px;
    }

    h2 {
      margin-bottom: 0;
      font-size: 30px;
      font-weight: 400;
      color: #887979;

      @media screen and (max-width: 992px) {
        font-size: 25px;
      }

      span {
        color: var(--mainColor);
        font-weight: 600;
      }
    }

    .limitedtime {
      font-size: 22px;
      color: #887979;
      font-weight: 400;

      @media screen and (max-width: 992px) {
        font-size: 18px;
      }
    }

    h3 {
      margin-bottom: 8px;
      color: #300787;
    }

    h4 {
      margin-bottom: 20px;
    }

    .registration {
      display: flex;

      @media screen and (max-width: 992px) {
        justify-content: center;
        flex-wrap: wrap;
      }

      .countryBox {
        border: 1px solid var(--mainColor);
        border-radius: 4px 0 0 4px;
        background: #fff;
        display: flex;
        align-items: center;

        @media screen and (max-width: 767px) {
          width: 100%;
          border-radius: 4px;
          margin-bottom: 10px;
        }

        .contryicons {
          align-items: center;
          display: flex;
          padding: 0 15px;
          position: relative;

          &::after {
            border: 0.5px solid #9a9a9a;
            position: absolute;
            content: "";
            right: 0;
            height: 27px;
          }
        }

        span {
          padding: 0 7px;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        input {
          background: none;
          border: none;
          padding: 11px 0;

          &:focus-visible {
            border: none;
            outline: none;
          }
        }
      }

      .btn {
        background: var(--mainColor);
        border: 1px solid var(--mainColor);
        border-radius: 0px 4px 4px 0px;
        color: #fff;
        padding: 11px;
        font-size: 15px;
        display: flex;
        align-items: center;
        opacity: unset;

        &:focus {
          background: #fff;
          color: #300787;
        }

        @media screen and (max-width: 767px) {
          width: 100%;
          border-radius: 4px;
          justify-content: center;
        }

        .icon {
          max-width: 20px;
          margin-left: 5px;
        }
      }
    }
    h6 {
  max-width: 455px;
  text-decoration: underline;
  text-align: center;
  color: #000;
 @media screen and (max-width:992px) {
  margin: 0 auto;
 }
    }
  }

  .slider-Img {
    border-radius: 16px;

    img {
      width: 100%;
      object-fit: cover;
      border-radius: 16px;
    }
  }
}

//banner end

//benefits start

.benefits-section {
  background: #f8f5fe;
  padding-top: 15px;

  .benefites-heading {
    color: var(--mainColor);
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 0;

    @media screen and (max-width: 992px) {
      font-size: 25px;
    }

    span {
      color: var(--mainColor);
    }
  }

  .benefites-subheading {
    font-size: 22px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
  }

  .card {
    border-radius: 16px;
    border: 1px solid #d4d4d9;
    gap: 20px;
    height: 100%;

    .btn-primary {
      display: none;
      padding: 7px 10px;

      @media screen and (max-width: 767px) {
        display: block;
        margin-top: 10px;
      }
    }

    .card-img {
      width: 100%;
      height: 195px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 16px 16px 0 0;
        object-fit: cover;
      }
    }

    .card-contain {
      padding: 0 10px 20px;

      h2 {
        color: var(--mainColor);
        font-size: 28px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 5px;

        @media screen and (max-width: 992px) {
          font-size: 25px;
        }
      }

      p {
        font-size: 18px;
        font-weight: 300;
        color: #524949;
        margin: 0 0 8px 0;
        line-height: normal;
      }
    }
  }
}

//benefits end

//ProcessWrapper start

.ProcessSection {
  @media screen and (max-width: 992px) {
    padding-bottom: 0;
  }

  h1 {
    margin-bottom: 20px;

    span {
      color: var(--mainColor);
      border-bottom: 1px solid var(--mainColor);
    }
  }

  h3 {
    margin-bottom: 40px;
  }

  .col-lg-2 {
    .processBlock {
      @media screen and (max-width: 992px) {
        margin-bottom: 50px;
      }

      h5 {
        margin: 12px 0;
      }

      .bottomText {
        border: 1px solid #d4d4d9;
        border-radius: 8px;
        padding: 10px 15px;

        @media screen and (max-width: 992px) {
          max-width: 200px;
          margin: 0 auto;
        }
      }

      .counterProcess {
        background: var(--mainColor);
        box-shadow: 0px 100px 200px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        width: 67px;
        height: 67px;
        color: #fff;
        font-weight: 500;
        font-size: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin: 0 auto;

        &::after {
          @media screen and (max-width: 992px) {
            display: none;
          }
        }
      }

      .processImg {
        width: 200px;
        height: 200px;
        margin-top: 37px;

        @media screen and (max-width: 992px) {
          margin: 10px auto 0;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    &:first-child {
      .counterProcess {
        margin: 0;

        @media screen and (max-width: 992px) {
          margin: 0 auto;
        }

        &::after {
          position: absolute;
          content: "";
          background: url(../../images/Home/bottom-bg.svg) no-repeat;
          width: 374px;
          height: 50px;
          background-size: 95%;
          left: 70px;
          right: 0;
          background-position: inherit;
        }
      }
    }

    &:nth-child(2) {
      .counterProcess {
        &::after {
          position: absolute;
          content: "";
          background: url(../../images/Home/top-bg.svg) no-repeat;
          width: 325px;
          height: 45px;
          background-size: 90%;
          left: 70px;
          right: 0;
          background-position: inherit;
        }
      }
    }

    &:nth-child(3) {
      .counterProcess {
        &::after {
          position: absolute;
          content: "";
          background: url(../../images/Home/bottom-bg.svg) no-repeat;
          width: 330px;
          height: 40px;
          background-size: 90%;
          left: 70px;
          right: 0;
          background-position: inherit;
        }
      }
    }
  }
}

//ProcessWrapper end

//HelpSection start

.HelpSection {
  margin-bottom: 42px;

  @media screen and (max-width: 992px) {
    text-align: center;
  }

  h4 {
    @media screen and (max-width: 992px) {
      margin-bottom: 15px;
    }
  }

  h5 {
    margin-bottom: 17px;

    @media screen and (max-width: 992px) {
      margin-bottom: 25px;
    }
  }

  .contactBlock {
    display: flex;
    gap: 20px;

    @media screen and (max-width: 992px) {
      justify-content: center;
    }

    @media screen and (max-width: 992px) {
      flex-wrap: wrap;
      gap: 10px;
    }

    .contactDetail {
      border: 1px solid #d9d9d9;
      border-radius: 5px;
      padding: 6px 15px;

      &:last-child {
        a {
          &::before {
            background: url(../../images/Home/emailIcon.svg) no-repeat;
            height: 22px;
            background-size: contain;
            max-width: 25px;
            width: 100%;
            background-position: center;
            top: 2px;
            background-size: 25px;
          }
        }
      }

      a {
        font-weight: 600;
        font-size: 18px;
        color: var(--mainColor);
        text-decoration: none;
        position: relative;
        padding-left: 35px;

        &:hover {
          text-decoration: underline;
        }

        &::before {
          content: "";
          position: absolute;
          background: url(../../images/Home/PhoneIcon.svg) no-repeat;
          height: 22px;
          background-size: contain;
          max-width: 25px;
          width: 100%;
          background-position: center;
          background-size: 20px;
          left: 0;
        }
      }
    }
  }
}

//HelpSection end
input {
  width: 250px;
  padding: 10px;
  border-radius: 2px;
  border: 1px solid #ccc;
}

input::placeholder {
  color: #bbb;
}

.contact_section {
  position: sticky;
  bottom: 15px;
  padding: 0 15px;
  display: flex;
  gap: 25px;
  justify-content: end;

  img {
    max-width: 35px;
  }
}

.zoom-in-out-box {
  width: 40px;
  animation: zoom-in-zoom-out 1s ease infinite;
  cursor: pointer;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.5, 1.5);
  }

  100% {
    transform: scale(1, 1);
  }
}

// ....footer

.page-footer {
  background-color: #131313;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(//kiko.link/wp-content/uploads/2018/02/footer.png) !important;
  padding: 25px 0 10px 0;
}

.footer-widget-area {
  padding: 30px 0 20px 0;
}

.widget.widget_text .kiko-logo img {
  max-width: 91px;
  width: 100%;
  margin: 0 0 30px 0;
}

.footer-widget-area .widget.widget_text {
  margin-bottom: 20px;
}

.footer-widget-area .textwidget:nt {
  color: #ffffffbf;
}

.footer-widget-area .textwidget p,
p > a {
  line-height: 1.6em;
  font-size: 16px;
  color: #fff;
  text-decoration: none;
}

.footer-widget-area .textwidget p,
p > a:hover {
  color: #fff;
  text-decoration: none;
}

.textwidget .socialIcon img {
  max-width: 20px;
}

.footer-widget-area .textwidget ul {
  padding: 0;
  margin: 0;
}

.footer-widget-area .textwidget ul li {
  display: inline-block;
  margin: 0 20px 0 0;
}

.menu-footer-container .menu {
  column-count: 2;
  list-style: none;
  padding: 0;
}

.menu-footer-container .menu .menu-link {
  font-size: 17px;
  font-weight: 500;
  margin: 0;
  color: #fff;
  text-decoration: none;
}

.menu-footer-container .menu .menu-link:hover {
  color: #a993db;
}

.menu-footer-container .menu .menu-item {
  padding: 12px 0;
}

.copyright-block {
  position: relative;
  background-color: #131313;
  padding: 22px 0;
  overflow: hidden;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.copyright-block p {
  color: #fff;
  text-align: left;
  font-size: 14px;
  margin: 0;
}
.downloadAppButtons {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 15px;
  margin: 50px 0;
  @media screen and (min-width: 768px) {
    max-width: 300px;
  }
}
.AppButtons {
  width: 50%;
  border: 0.5px solid #797979;
  border-radius: 7px;
}
.AppButtons .downloadLink {
  display: block;
}
.AppButtons img {
  width: 100%;
  height: 100%;
}
.purple {
  color: var(--mainColor);
}
.ondc-recived {
  text-align: center;
  max-width: 700px;
  margin: 0 auto 30px;
  h4 {
    font-weight: 700;
    color: var(--mainColor);
  }
  h6 {
    font-weight: 600;
    color: #000;
    line-height: 30px;
    .btn {
      border-radius: 5px;
      padding: 3px 9px;
      background: var(--mainColor);
      border: 1px solid var(--mainColor);
    }
  }
}
.char {
  padding: 0 10px;
  background: #000;
  color: #fff;
  font-size: 30px;
}
.char + .char {
  margin-left: 5px;
}
.sep {
  padding: 0 2px;
}
.ondc-recived-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
  }
}
.registration--btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}