.modal-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 18px;

  img {
    cursor: pointer;
    max-width: 13px;
  }
}

.modal-header {
  padding: 13px;
}

.sliderImg {
  max-width: 150px;
  width: 100%;
  height: 130px;

  img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    border-radius: 2px;
  }
}

.uploadImageModal {
  cursor: pointer;
  margin: 0;
  text-decoration: underline;
}

.view-return-slider {
  // border-bottom: 1px solid #C0C0C7;
  margin-bottom: 8px;
  display: flex;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  max-width: 450px;
  justify-content: center;

  .viewReturnImg {
    min-width: 150px;
    height: 130px;
    margin: 0 10px;

    img {
      min-width: 100%;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  p {
    font-weight: 600;
    text-align: center;
    padding: 7px 0 0 0;
  }
}
// .view-return-text {
//     text-align: center;
// }

.rejection-modal {
  .modal-body {
    border: 1px solid #a1a1ac;
    border-radius: 8px;
    padding: 8px;

    .type {
      margin-bottom: 3px;
    }
  }
}

.packedOrder {
  border-radius: 4px;
  border: 1px solid #eae6f3;
  background: #eae6f3;
  padding: 20px 15px;
  form {
    display: flex;
    gap: 20px;
    justify-content: center;
  }
}
.delivery-option {
  border: 1px solid #e2e2e5;
  margin-bottom: 16px;
  padding: 18px 20px;
  border-radius: 4px;
  margin-top: 16px;
  h5 {
    margin-bottom: 12px;
    text-align: center;
    font-size: 20px;
  }

  .options-block {
    max-width: 487px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
}

.parcel-weight {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e2e2e5;
  border-radius: 4px;
  padding: 10px 20px;
  gap: 15px;
  margin-bottom: 16px;

  h3 {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
  }
}

.selfDeliveryModal {
  text-align: center;

  .btn-secondary {
    min-width: auto;
  }

  h6 {
    color: #f85f5f;
    position: relative;
    display: block;
    margin-top: 10px;

    &::before {
      content: "";
      position: absolute;
      background: url(../../images/Inventry/info.svg) no-repeat;
      width: 25px;
      height: 25px;
      background-size: 17px;
    }

    span {
      padding-left: 25px;
    }
  }
}

.DeliveryChargesModal {
  .table-heading {
    background: #59399f;
    padding: 8px 12px;
    text-align: center;
    height: 40px;

    h5 {
      margin: 0;
      color: #fff;
    }

    th {
      color: #fff;
      font-weight: 500;
      font-size: 20px;
    }
  }

  .table-responsive {
    border: 1px solid #c0c0c7;

    td {
      padding-top: 10px;
    }
  }

  ul {
    list-style: none;

    li {
      font-size: 17px;

      span {
        color: #ff0000;
        font-size: 21px;
        margin-right: 5px;
      }
    }
  }

  p {
    font-size: 18px;
    margin: 10px 0 0 0;
    color: #333333;
  }
}

.upload-image-modal {
  .preview-image {
    // max-width: 248px;
    max-width: 100%;
    width: 100%;
    height: 123px;
    background: #d9d9d9;
    border-radius: 8px;
    // margin: 0 auto 12px;
    margin-bottom: 12px;

    img {
      max-width: 100%;
      height: 100%;
      border-radius: 8px;
      object-fit: contain;
      width: 100%;
    }
  }

  // .btn-primary {
  //     max-width: 248px;
  // }
}

.view-order-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
  @media screen and (max-width:992px) {
    flex-wrap: wrap;
  }
  .btn {
    font-size: 13px;
    padding: 8px;
  }
}