.page-header:before {
    background-color: #000;
    zoom: 1;
    filter: alpha(opacity=70);
    -webkit-opacity: 0.7;
    -moz-opacity: 0.7;
    opacity: 0.7;
    content: "";
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.page-header .container {
    position: relative;
    z-index: 2;
    padding-top: 115px;
    padding-bottom: 115px;
}

.page-header h1 {
    margin: 0;
    color: #fff !important;
    color: var(--white);
    position: relative;
    z-index: 11;
    line-height: 1.15em;
    font-size: 48px;
    padding: 0 15px;

    @media (max-width: 1599px) {
        padding: 0 100px;
    }
}

.page-header .breadcrumbs {
    list-style: none;
    margin: 14px 0 0px 0;
    padding: 0;
}

.breadcrumbs li {
    display: inline-block;
    margin: 0 0 0 0px;
    font-size: 18px;
    color: #fff !important;
    color: var(--white);
    font-family: 'Magra', serif;
    font-family: var(--font-headers), serif;
    font-weight: 700;
}

.breadcrumbs li:not(:last-child):after {
    margin: 0px 8px 0 13px;
    vertical-align: middle;
    position: relative;
    top: -2px;
    display: inline-block;
    background: url(../../../images/arrow-right-solid.svg) no-repeat;
    content: "";
    width: 10px;
    height: 10px;
    background-size: 10px;
}

.breadcrumbs li a {
    color: #a993db;
    text-decoration: none;
}

.page-header {
    background-image: url(../../../images/footer-header-img.jpg) !important;
}

.page-header {
    padding-bottom: 9px;
    margin: 40px 0 20px;
    border-bottom: 1px solid #eee;
}

.header-wrapper {
    @media (max-width: 1299px) {
        background-size: cover;
    }
}

.page-header {
    color: #fff;
    color: var(--white);
    text-align: center;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 0;
    background-repeat: no-repeat;
    position: relative;
    background-position: 50% 0px;
    background-size: cover;
}

.header-wrapper {
    background-color: #131313;
    background-color: var(--black);
    color: #fff;
    color: var(--white);
    position: relative;
    z-index: 20;
    background-size: cover;
}

.margin-default {
    padding-top: 100px;
    padding-bottom: 100px;
}

.condition-of-sales {
    margin: 42px 0 16px;
}

.entry-content p {
    font-size: 16px;
    color: #000;
}