@font-face {
    font-family: 'Nunito';
    src: url('Nunito-ExtraBold.eot');
    src: url('Nunito-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('Nunito-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('Nunito-Black.eot');
    src: url('Nunito-Black.eot?#iefix') format('embedded-opentype'),
        url('Nunito-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('Nunito-Bold.eot');
    src: url('Nunito-Bold.eot?#iefix') format('embedded-opentype'),
        url('Nunito-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('Nunito-Medium.eot');
    src: url('Nunito-Medium.eot?#iefix') format('embedded-opentype'),
        url('Nunito-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('Nunito-Light.eot');
    src: url('Nunito-Light.eot?#iefix') format('embedded-opentype'),
        url('Nunito-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('Nunito-Regular.eot');
    src: url('Nunito-Regular.eot?#iefix') format('embedded-opentype'),
        url('Nunito-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('Nunito-ExtraLight.eot');
    src: url('Nunito-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('Nunito-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito';
    src: url('Nunito-SemiBold.eot');
    src: url('Nunito-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Nunito-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

