.dashboardWrapper {
  .RightBlock {
    .shopDetailsWrapper {
      display: flex;
      height: calc(100vh - 60px);

      .leftBlock {
        width: 25%;
        background: #f2f5fa;

        @media screen and (max-width: 767px) {
          width: 30%;
        }
      }

      .tabs-left {
        border-bottom: none;
        display: flex;
        flex-direction: column;
      }

      .tabs-left > li {
        padding: 10px 10px 10px 20px;
        box-sizing: border-box;

        @media screen and (max-width: 1536px) {
          padding: 8px 10px 8px 20px;
        }

        @media screen and (max-width: 767px) {
          padding-left: 15px;
        }

        a {
          display: flex;
          align-items: center;
          gap: 12px;
          text-decoration: none;
          font-weight: 500;
          font-size: 16px;
          color: #72727a;
          width: 100%;

          @media screen and (max-width: 1536px) {
            font-size: 14px;
          }

          @media screen and (max-width: 767px) {
            padding-left: 15px;
            padding-left: 0;
          }

          @media screen and (max-width: 480px) {
            text-align: center;
            flex-wrap: wrap;
            justify-content: center;
          }

          svg {
            max-width: 20px;
            width: 100%;
            height: 20px;
          }
        }

        img {
          max-width: 24px;
        }

        .gray {
          display: block;
        }

        .blue {
          display: none;
        }
      }

      .tabs-left > li.active {
        background: #fff;
        color: var(--mainColor);

        .gray {
          display: none;
        }

        .blue {
          display: block;
        }
      }

      .tabs-left > li.active > a,
      .tabs-left > li.active > a:hover,
      .tabs-left > li.active > a:focus {
        color: var(--mainColor);
      }

      .rightBlock {
        position: relative;
        width: 75%;
        padding: 14px 10px 10px 20px;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;

        @media screen and (max-width: 767px) {
          width: 70%;
        }

        .tab-content {
          h1 {
            font-weight: 500;
            font-size: 24px;
            color: var(--mainColor);
            margin-bottom: 15px;

            @media screen and (max-width: 1536px) {
              font-size: 17px;
              margin-bottom: 6px;
            }
          }

          .tab-pane {
            .formBlock {
              padding: 0;
              list-style: none;

              li {
                border-bottom: 1px solid #e2e2e5;
                padding: 13px 0;

                @media screen and (max-width: 1536px) {
                  padding: 8px 0;
                }

                h6 {
                  font-weight: 400;
                  font-size: 16px;
                  color: #72727a;

                  @media screen and (max-width: 1536px) {
                    font-size: 14px;
                    margin-bottom: 4px;
                  }
                }

                p {
                  font-weight: 500;
                  font-size: 16px;
                  color: #323237;
                  margin: 0;

                  @media screen and (max-width: 1536px) {
                    font-size: 14px;
                  }
                }
              }
            }

            .downloadFile {
              display: flex;
              align-items: center;
              gap: 15px;
              margin-bottom: 15px;

              @media screen and (max-width: 767px) {
                flex-wrap: wrap;
                margin-bottom: 25px;
              }

              .filePreview {
                width: 218px;
                height: 100px;
                background: #d9d9d9;
                border-radius: 8px;
                object-fit: cover;

                @media screen and (max-width: 480px) {
                  width: 100%;
                }
              }

              .btn {
                @media screen and (max-width: 480px) {
                  width: 100%;
                }
              }
            }

            .passwordStrong {
              @media screen and (max-width: 992px) {
                text-align: left;
              }

              p {
                font-weight: 600;
                color: #000000;
                font-size: 14px;
                margin-bottom: 7px;
              }

              .numbered-list {
                list-style-type: none;
                padding-left: 0;

                li {
                  position: relative;
                  padding-left: 30px;
                  font-weight: 500;
                  font-size: 14px;
                  color: #72727a;
                  line-height: 25px;
                  margin-bottom: 3px;

                  &::before {
                    content: "";
                    height: 12px;
                    width: 12px;
                    color: rgb(255, 255, 255);
                    text-align: center;
                    position: absolute;
                    left: 0;
                    top: 5px;
                    background: #d9d9d9;
                    border-radius: 50%;
                  }

                  &::marker {
                    font-size: 43px;
                    color: #d9d9d9;
                    line-height: 15px;
                  }
                }
              }
            }

            .detailsForm {
              input {
                border-radius: 4px;
                max-width: 100%;
                margin-bottom: 18px;
                color: #000000;
                display: block;
                padding: 6px 15px;

                &:focus {
                  outline: none;
                }

                &::placeholder {
                  color: #72727a;
                }
              }
            }

            .store-timing {
              h6 {
                margin: 0;
                @media screen and (max-width: 992px) {
                  margin-bottom: 8px;
                }
              }

              [type="radio"]:checked,
              [type="radio"]:not(:checked) {
                position: absolute;
                left: -9999px;
              }

              [type="radio"]:checked + label,
              [type="radio"]:not(:checked) + label {
                position: relative;
                padding-left: 30px;
                cursor: pointer;
                line-height: 20px;
                display: inline-block;
                color: #323237;
                font-size: 16px;
                font-weight: 600;
              }

              [type="radio"]:checked + label:before,
              [type="radio"]:not(:checked) + label:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 18px;
                height: 18px;
                border: 1px solid #ddd;
                border-radius: 100%;
                background: #fff;
              }

              [type="radio"]:checked + label:after,
              [type="radio"]:not(:checked) + label:after {
                content: "";
                width: 18px;
                height: 18px;
                background: #1cce90;
                border: 1px solid #59595f;
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 100%;
                -webkit-transition: all 0.2s ease;
                transition: all 0.2s ease;
              }

              [type="radio"]:not(:checked) + label:after {
                opacity: 0;
                -webkit-transform: scale(0);
                transform: scale(0);
              }

              [type="radio"]:checked + label:after {
                opacity: 1;
                -webkit-transform: scale(1);
                transform: scale(1);
              }

              .select-day {
                display: flex;
                align-items: center;
                margin-bottom: 16px;
                position: relative;

                input {
                  width: 18px;
                  height: 18px;
                  opacity: 0;
                  position: absolute;

                  @media screen and (max-width: 1536px) {
                    width: 14px;
                    height: 14px;
                  }
                }

                label {
                  font-weight: 500;
                  font-size: 16px;
                  color: #323237;
                  padding-left: 30px;
                  background-position: left center;
                  background-size: auto 100%;
                  background-repeat: no-repeat;
                  padding-left: 37px;
                  background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='iso-8859-1'?%3E %3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E %3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='533.333px' height='533.333px' viewBox='0 0 533.333 533.333' style='enable-background:new 0 0 533.333 533.333;' xml:space='preserve'%3E %3Cg%3E %3Cpath d='M0,0v533.333h533.333V0H0z M500,500H33.333V33.333H500V500z'/%3E %3C/g%3E %3C/svg%3E ");
                  background-size: 18px;

                  @media screen and (max-width: 1536px) {
                    font-size: 14px;
                    background-size: 14px;
                    padding-left: 25px;
                  }
                }

                input[type="checkbox"]:checked + label {
                  background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='iso-8859-1'?%3E %3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E %3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='533.333px' height='533.333px' viewBox='0 0 533.333 533.333' style='enable-background:new 0 0 533.333 533.333;' xml:space='preserve'%3E %3Cg%3E %3Cpath d='M0,0v533.333h533.333V0H0z M500,500H33.333V33.333H500V500z M400,116.667L233.333,283.333l-100-100L66.667,250 l166.667,166.667l233.333-233.333L400,116.667z'/%3E %3C/g%3E %3C/svg%3E ");
                }
              }
            }
          }
        }

        .ImgPreview {
          max-width: 248px;
          height: 123px;
          object-fit: cover;
          background: #d9d9d9;
          border-radius: 8px;
          width: 100%;
          margin: 0 auto;

          img {
            width: 100%;
            max-width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
          }
        }

        .upload-image-modal {
          position: fixed;
          padding: 25px 77px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 0;
          background: rgb(255, 255, 255);
          border-radius: 8px;
          border: 0.720565px solid #b4b4bd;
          max-width: 375px;
          // width: 100%;

          .uploaded-images {
            display: flex;
            gap: 20px;

            .imagesPreview {
              max-width: 100px;
              width: 100%;
              height: 80px;

              img {
                max-width: 100%;
                width: 100%;
                height: 100%;
              }
            }
          }

          .Btnflex {
            display: flex;
            gap: 10px;
            margin-top: 20px;
          }
        }

        .uploadBtn {
          position: relative;
          cursor: pointer;
          width: 100%;
          min-height: 41px;

          @media screen and (max-width: 1536px) {
            padding: 4px;
          }

          input {
            opacity: 0;
            padding: 0;
            height: 100%;
            z-index: 8;
            position: relative;
            cursor: pointer;
            width: 100%;
            padding: 8px 10px;
          }

          p {
            top: 50%;
            position: absolute;
            margin: 0 auto;
            transform: translate(-50%, -50%);
            left: 50%;
            white-space: nowrap;
            color: #fff;

            .icon {
              max-width: 20px;
              height: auto;
            }
          }
        }
      }
    }

    .section-heading {
      padding: 12px 20px;
      border-bottom: 1px solid #d4d4d9;
    }

    .section-title {
      padding: 13px 20px;
      border-bottom: 1px solid #d4d4d9;

      h1 {
        font-weight: 600;
        font-size: 24px;
        color: #323237;
        margin: 0;
        display: flex;
        align-items: center;
        gap: 8px;

        @media screen and (max-width: 1536px) {
          font-size: 20px;
        }
      }
    }
  }
}

.contact-us-headline {
  text-align: right;
  position: inherit;
  right: 10px;
  bottom: 0;
  margin: 20px 0;

  @media screen and (max-width: 1536px) {
    bottom: auto;
    font-size: 14px;
  }

  @media screen and (max-width: 767px) {
    text-align: left;
    padding: 10px 15px;
    bottom: 0;
  }

  img {
    max-width: 30px;
    margin: 0 8px;
  }
}

#popover-trigger {
  background: none;
  border: none;
  color: #1340a7;
  text-decoration: underline;
}

#popover-content {
  display: none;
  position: absolute;
  background: #f7f7f7;
  border-radius: 8px;
  padding: 16px 10px;
  bottom: 38px;
  right: 0;

  &::after {
    content: "";
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 10px solid #f7f7f7;
    position: absolute;
    bottom: -10px;
    right: 33px;

    @media screen and (max-width: 767px) {
      right: 105px;
    }
  }

  p {
    margin: 0;
    display: flex;
    flex-direction: column;

    /* Additional styles as needed */
    a {
      display: inline-block;
      font-weight: 500;
      font-size: 14px;
      color: #300787;
      text-decoration: none;
      padding: 8px;
      border: 1px solid #d3cbcb;
      border-radius: 4px;

      img {
        max-width: 24px;
        margin-right: 8px;
      }
    }
  }
}

#popover-trigger:hover + #popover-content,
#popover-content:hover {
  display: block;
}

.storetiming {
  padding-top: 8px;

  .form-label {
    @media screen and (max-width: 1536px) {
      font-size: 14px;
    }
  }

  h5 {
    color: var(--mainColor);

    @media screen and (max-width: 1536px) {
      font-size: 16px;
    }
  }

  h6 {
    @media screen and (max-width: 1536px) {
      font-size: 14px;
    }
  }

  .Cancellable {
    label {
      @media screen and (max-width: 1536px) {
        font-size: 14px !important;
        line-height: 16px !important;
      }
    }
  }
}

.borderRight {
  border-right: 1px solid #000;
}

.time-picker {
  @media screen and (max-width: 1536px) {
    font-size: 12px;
  }

  &:after {
    @media screen and (max-width: 1536px) {
      width: 14px;
      height: 14px;
      background-size: 12px;
    }
  }
}

.date-picker {
  @media screen and (max-width: 1536px) {
    font-size: 12px;
  }
}

.storeServiceableList {
  list-style: none;
  padding: 20px 0 0 0;
  margin: 0;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;

  li {
    padding: 6px;
    border-radius: 4px;
    border: 1px solid #b8b8b8;
  }
}

.storeServiceableList2 {
  list-style: none;
  padding: 20px 0 0 0;
  margin: 0;

  li {
    padding: 6px 0;
  }
}

.description {
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 10px 15px;
  min-height: 100px;
  margin-bottom: 12px;

  @media screen and (max-width: 767px) {
    padding: 10px;
    font-size: 16px;
  }

  &:focus-visible {
    outline: none;
  }
}

.additionaldetail {
  display: flex;
  gap: 20px;

  @media screen and (max-width: 1300px) {
    flex-wrap: wrap;
  }
}

.CopyUrl {
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 9px 15px;
  word-break: break-all;
  min-height: 36px;

  @media screen and (max-width: 767px) {
    padding: 10px;
  }

  p {
    font-size: 15px;
    font-weight: 500;
    color: #000;
    display: flex;
    align-items: center;

    @media screen and (max-width: 767px) {
      font-size: 12px;
      white-space: nowrap;
    }

    span {
      white-space: nowrap;
    }

    input {
      border: none;
      margin-bottom: 0 !important;
      padding: 0 !important;
      border-radius: 0 !important;
      color: #000 !important;

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
}

.edit-title {
  font-size: 23px;
  font-weight: 500;
  color: #000;
}

.edit-para {
  color: gray;
  font-size: 18px;
}

.green {
  color: #04c806 !important;
}

.edit-btn {
  border: 1px solid var(--mainColor);
  border-radius: 4px;
  background: #fff;
  color: #000;
  padding: 4px 13px;
  font-size: 14px;
  line-height: normal;
}

.store-local-order {
  display: flex;
  margin-top: 40px;

  @media screen and (max-width: 1350px) {
    flex-wrap: wrap;
    justify-content: center;
  }

  gap: 40px;

  .orders-list {
    border-radius: 8px;
    border: 1px solid #b8b8b8;
    padding: 12px;

    label {
      @media screen and (max-width: 1450px) {
        font-size: 14px;
      }
    }

    .order-title {
      font-size: 20px;
      font-weight: 500;
      color: var(--mainColor);
      text-align: center;
      width: 100%;
    }

    .DeliveryCharges {
      input {
        font-size: 15px;
        background: #e2e2e5;
        border-radius: 4px;
        text-align: center;
        max-width: 160px;

        &:focus {
          box-shadow: none;
        }
      }
    }

    .Cancellable {
      border-radius: 6px;
      padding: 4px 8px;
      border: 1px solid #b8b8b8;
    }
  }
}

.edit--btns {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;

  @media screen and (max-width: 991px) {
    flex-direction: row;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }

  .btn {
    width: 100%;
  }
}

.sellercontact {
  padding: 10px !important;
  width: 100%;
  display: block;

  @media screen and (max-width: 992px) {
    width: auto;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  p {
    margin: 0;
    color: var(--mainColor);
  }
}

.cancellable-btns {
  justify-content: end;

  @media screen and (max-width: 767px) {
    justify-content: start;
    margin-top: 10px;
  }

  .Cancellable {
    width: 100%;
  }
}

.coupon-details-form {
  background: #fff;
  padding: 25px;
  border-radius: 10px;
  border: 1px solid #000;

  @media screen and (max-width: 767px) {
    padding: 20px;
  }

  input {
    font-size: 13px;
    padding: 8px 12px;
    width: 100%;
  }

  h6 {
    font-size: 18px;
    color: #000;
    font-weight: 600;
    margin-bottom: 30px;
  }

  h5 {
    font-size: 16px;
    color: #000;
    font-weight: 600;
    margin-bottom: 15px;
  }

  label {
    font-size: 14px;
    color: #000;
    margin-bottom: 7px;
    font-weight: 500;
  }

  span {
    font-size: 13px;
    color: #979696;
    font-weight: 400;
  }

  .cross-icon {
    position: absolute;
    top: -11px;
    right: -7px;
    background: white;
    border-radius: 10px;
    border: none;
    cursor: pointer;

    img {
      width: 20px;
      height: 20px;
    }
  }
}

.discount-Type {
  .radio {
    margin: 0.5rem;
    display: inline-block;

    input[type="radio"] {
      position: absolute;
      opacity: 0;

      + .radio-label {
        &:before {
          content: "";
          background: #f4f4f4;
          border-radius: 100%;
          border: 1px solid darken(#f4f4f4, 25%);
          display: inline-block;
          width: 18px;
          height: 18px;
          position: relative;
          margin-right: 1em;
          vertical-align: middle;
          cursor: pointer;
          text-align: center;
          transition: all 250ms ease;
        }
      }

      &:checked {
        + .radio-label {
          &:before {
            background-color: #59399f;
            box-shadow: inset 0 0 0 4px #f4f4f4;
            border: 1px solid #59399f;
          }
        }
      }

      &:focus {
        + .radio-label {
          &:before {
            outline: none;
            border-color: #3197ee;
          }
        }
      }

      &:disabled {
        + .radio-label {
          &:before {
            box-shadow: inset 0 0 0 4px #f4f4f4;
            border-color: darken(#f4f4f4, 25%);
            background: darken(#f4f4f4, 25%);
          }
        }
      }

      + .radio-label {
        &:empty {
          &:before {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.Extras-list {
  // padding: 30px 0;
  // border-top: 2px solid #ccc;

  p {
    font-size: 18px;
    color: #000;
    margin-bottom: 10px;
    font-weight: 600;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      font-size: 16px;
      color: #000;
      font-weight: 600;
      margin-bottom: 8px;
    }
  }

  .checkbox {
    margin: 0 12px 0 0;
    vertical-align: middle;
    position: relative;
    width: 15px;
    height: 15px;
    display: inline-block;
  }
}

.coupons-main-heading {
  font-size: 17px;
  color: #000;
  font-weight: 600;
  margin: 0;
  padding: 20px 0;
}

.CouponsActive {
  background: #ffe81a;
  color: #000;
  padding: 2px 6px;
  border-radius: 4px;
}

.coupons-table {
  thead {
    tr {
      th {
        font-weight: 500;
        font-size: 14px;
        white-space: nowrap;
        text-align: center;
        background: rgba(243, 243, 243, 0.8196078431);
      }
    }
  }

  tbody {
    border-bottom: 1px solid #d4d4d9;
    vertical-align: middle;

    td {
      color: #323237;
      font-weight: 600;
      font-size: 12px;
      line-height: normal;
      padding: 10px;
      text-align: center;
    }
  }
}

.coupon-img {
  max-width: 200px;
  height: 150px;
  margin-bottom: 15px;
  width: 100%;
  position: relative;
  margin-top: 20px;

  img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.search-listing-container {
  ul {
    list-style: none;
    padding: 0;
    margin: 10px 0 0 0;

    .search--list {
      span {
        color: #000;
        cursor: pointer;
      }
    }
  }
}

.Selected-Catelogue-list {
  padding: 0;
  margin: 0;
  list-style: none;
  max-height: 200px;
  overflow: auto;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    span {
      color: #008000;
    }
  }

  .btn {
    background: none;
    border: none;
  }
}

.search-listing-container ul {
  max-height: 200px;
  overflow: auto;
}

.additional-details-btn {
  display: flex;
  gap: 5px;

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
  }
}
.upload-Image {
  min-height: 41px;
}

.whatsappButton {
  border: 1px solid #d3cbcb;
  border-radius: 4px;
  color: #300787;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  padding: 8px;
  text-decoration: none;
}
.serviceable-radius {
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  padding: 14px 18px;
  font-size: 15px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width:767px) {
    padding: 9px 15px;
  }
}
.serviceable-radius-dropdown {
  background: #ffffff;
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: #dcdcdc;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.12);
  border-radius: 0px 0px 8px 8px;
  display: none;
  margin-top: -6px;
}
.serviceable-list {
  padding: 0;
  margin: 0;
  list-style: none;
  li {
    border-bottom: 1px solid #dcdcdc;
    padding: 14px 18px;
    &:last-child {
      border: 0;
    }
  }
}
.type [type="radio"]:checked,
.type [type="radio"]:not(:checked) {
  position: absolute;
  left: 0;
  width: 18px;
  height: 18px;
  opacity: 0;
}
.type [type="radio"]:checked + label,
.type [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #323237;
  font-size: 16px;
  font-weight: 600;
}
.type [type="radio"]:checked + label:before,
.type [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
.type [type="radio"]:checked + label:after,
.type [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 18px;
  height: 18px;
  background: #1cce90;
  border: 1px solid #59595f;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 100%;
  transition: all 0.2s ease;
}
.type [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.serviceable-dropdown-show {
  display: block;
}
.delivery-charges-feild {
  padding: 14px 18px;
  &::placeholder {
    font-size: 15px;
    color: #a1a1ace6;
  }
  @media screen and (max-width:767px) {
    padding: 9px 15px;
  }
}
.serviceable-flex {
  display: flex;
  align-items: flex-start;
  margin-top: 15px;
  gap: 20px;
  flex-wrap: wrap;
}
.serviceable-radius-feild {
  @media screen and (max-width:767px) {
    width: 100%;
  }
}
.add-coupons-flex {
  display: flex;
  align-items: center;
  justify-content:space-between;
  margin-bottom: 20px;
  @media screen and (max-width:767px) {
    flex-wrap: wrap;
    gap: 10px;
  }
}