.showWrapper {
  width: calc(100% - 251px);
  position: absolute;
  left: 251px;
  display: inline-block;
  overflow-y: auto;
  height: 100vh;

  @media screen and (max-width: 992px) {
    width: 100%;
    left: 0;
    height: auto;
  }
  .brandlogo {
    max-width: 135px;
    margin-bottom: 25px;
  }
}

.kikoshopWrapper {
  height: 100vh;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1746px) {
    height: 100%;
    padding: 30px 0;
    display: unset;
  }

  @media screen and (max-width: 992px) {
    padding: 80px 0 30px 0;
  }
  .skipBtn {
    background: none;
    border: none;
    font-weight: 500;
    font-size: 22px;
    color: #323237;
    text-decoration: underline;
  }

  .shopDetailsBlock {
    .nav-pills {
      margin-bottom: 32px;

      .nav-link {
        border-radius: 0;
        text-align: center;

        @media screen and (max-width: 767px) {
          padding: 8px;
        }

        img {
          max-width: 55px;
          margin-bottom: 6px;

          @media screen and (max-width: 767px) {
            max-width: 40px;
          }
        }

        h6 {
          margin: 0;

          @media screen and (max-width: 767px) {
            font-size: 15px;
          }
        }

        .gray {
          display: block;
          margin: 0 auto 10px;
        }

        .blue {
          display: none;
        }
      }

      .nav-link.active {
        background: none;
        border-bottom: 5px solid #1ee29d;

        .gray {
          display: none;
        }

        .blue {
          display: block;
          margin: 0 auto 10px;
        }

        h6 {
          color: #59399f;
        }
      }
    }

    .uploadImgBlock.upload_img {
      @media screen and (max-width: 1390px) {
        flex-wrap: wrap;
        justify-content: center !important;
      }
    }

    .shopDetailsWrapper {
      display: flex;
      align-items: start;
      justify-content: space-between;
      flex-wrap: wrap;

      .shopDetails-list {
        width: 45%;

        @media screen and (max-width: 992px) {
          width: 100%;
        }

        &:last-child {
          width: 50%;
          border-left: 1px solid #d4d4d9;
          padding-left: 50px;

          @media screen and (max-width: 992px) {
            width: 100%;
            border: none;
            padding: 30px 0 0 0;
          }
        }

        .uploadImgBlock {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 15px;

          @media screen and (max-width: 767px) {
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 0;
          }

          .uploadBtn {
            position: relative;
            min-height: 41px;
            width: 100%;

            @media screen and (max-width: 490px) {
              width: 100%;
            }

            input {
              opacity: 0;
              height: 100%;
              z-index: 8;
              padding: 0;
              position: relative;
              cursor: pointer;
              width: 100%;
              min-width: 100%;
              padding: 8px 10px;
            }

            .upload-img {
              top: 50%;
              position: absolute;
              margin: 0 auto;
              transform: translate(-50%, -50%);
              left: 50%;
              white-space: nowrap;
              color: #fff;
              font-size: 14px;
              display: flex;
              align-items: center;
              gap: 3px;

              img {
                max-width: 17px;
                margin-left: 4px;
              }
            }

            button {
              background: none;
              border: none;
              color: #fff;
              padding: 11px 20px;
              white-space: nowrap;

              @media screen and (max-width: 992px) {
                font-size: 14px;
                padding: 11px;
              }

              img {
                margin-left: 8px;
                max-width: 23px;
              }
            }
          }

          .ImgPreview {
            max-width: 248px;
            height: 123px;
            object-fit: cover;
            background: #d9d9d9;
            border-radius: 8px;
            width: 100%;

            @media screen and (max-width: 480px) {
              max-width: 100%;
            }
          }

          .logoimg {
            width: 100%;
            max-width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
          }
        }

        .title {
          background: #f3f0f9;
          border-radius: 4px;
          padding: 13px;
          width: 100%;
          text-align: center;
          margin-bottom: 15px;

          h6 {
            font-weight: 500;
            font-size: 20px;
            color: #59399f;
          }
        }

        .checkGstNumber {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }

        .checkGstText {
          font-weight: 500;
          font-size: 14px;
          color: #59595f;
        }

        .nav-tabs {
          margin-bottom: 24px;
          border: none;

          .nav-link {
            border: none;
            color: #72727a;

            &:active {
              color: #72727a;
            }

            .bankdetailchecked {
              &:checked {
                background: red;
              }
            }
          }
        }
      }

      .share-location {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        flex-wrap: wrap;

        .location-btn {
          background: var(--mainColor);
          border: 1px solid var(--mainColor);
          color: #fff;
          border-radius: 4px;
          width: 48%;

          @media screen and (max-width: 490px) {
            width: 100%;
          }

          img {
            max-width: 18px;
            margin-left: 8px;
          }

          .btn {
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
            width: 100%;
          }
        }

        .location {
          display: flex;
          align-items: start;

          img {
            max-width: 20px;
            margin-right: 8px;
          }

          h6 {
            margin-bottom: 2px;
            font-weight: 500;
            font-size: 16px;
            color: #323237;
          }

          p {
            margin: 0;
            color: #323237;
            font-weight: 400;
            font-size: 14px;
          }
        }
      }

      ::-webkit-input-placeholder:after {
        content: "*";
      }

      .form-control {
        border: 1px solid #a1a1ac;
        border-radius: 10px;
        padding: 10px 15px;

        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}

// .addressmodalBlock {

//   .searchBlock {
//     box-shadow: 3px 3px 3px #0000001A;
//     border: 1px solid #00E49E;
//     border-radius: 15px;
//     padding: 16px 25px;
//     width: 100%;

//     @media screen and (max-width:767px) {
//       padding: 12px 18px;
//       margin-bottom: 25px;
//     }

//     display: flex;
//     align-items: center;
//     margin-bottom: 35px;

//     img {
//       margin-right: 17px;
//       max-width: 25px;
//     }

//     input {
//       background: none;
//       border: none;
//       width: 100%;
//       font-size: 24px;
//       color: #A3A3A3;
//       padding: 0;

//       &:focus-visible {
//         outline: none;

//       }
//     }
//   }

//   .currenLocation {
//     display: flex;
//     align-items: center;
//     margin-bottom: 37px;

//     img {
//       max-width: 40px;
//       margin-right: 15px;
//     }

//     button {
//       margin: 0;
//       font-size: 24px;
//       font-weight: 500;
//       background: none;
//       border: none;

//       @media screen and (max-width:767px) {
//         font-size: 20px;
//       }
//     }
//   }
// .redultListBlock {
//   height: 400px;
//   overflow-y: scroll;
// }
//   .resultList {
//     display: flex;
//     align-items: start;

//     &:last-child {
//       .resultText {
//         border: none;
//       }
//     }

//     img {
//       margin-right: 17px;
//       max-width: 25px;
//     }

//     .resultText {
//       border-bottom: 1px solid #818181;
//       margin-bottom: 15px;
//       width: 100%;

//       h4 {
//         font-size: 22px;
//         margin: 0;
//       }

//       p {
//         font-size: 20px;
//         color: #080808;
//         margin: 0 0 7px 0;
//       }
//     }

//   }

//   .searchResults {
//     h3 {
//       color: #676767;
//       font-size: 22px;
//       margin-bottom: 20px;
//     }

//   }
// }
.Loader {
  position: absolute;
  left: 0px;

  .ant-spin-dot-item {
    background-color: #fff !important;
  }

  .anticon-loading {
    color: #fff;
  }
}

.Loaderblue {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.agreement-modal-header {
  .modal-header {
    padding: 13px 0;
  }
  .modal-title {
    justify-content: center;
    // border-bottom: 1px solid #aaa;

    h6 {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      color: #000;
    }

    img {
      max-width: 85px;
    }
  }
}
.signature-captcha {
  height: 100%;
  p {
    margin: 0;
    padding: 0 5px 5px 5px;
  }
  img {
    max-width: 70px;
    width: 100%;
    height: 45px;
    margin: 0 auto;
    text-align: center;
    display: flex;
  }
}
.declaration-body {
  p {
    font-size: 14px;
    color: #000;
    font-weight: 500;
    margin-bottom: 9px;
    line-height: 27px;
    span {
      border-bottom: 1px dashed #000;
      padding: 0 5px;
    }
  }
}
.agreement-body {
  max-height: 400px;
  overflow-y: auto;

  p {
    font-size: 13px;
    color: #000;
    font-weight: 500;
    margin-bottom: 9px;
    span {
      border-bottom: 1px dashed #000;
      padding: 0 5px;
    }
  }
}
.declaration-body-modal {
  padding: 20px 20px 0;
  border-radius: 8px;

  p {
    .border-box {
      display: inline-block;
      padding: 5px 10px;
      border-radius: 4px;
      border: 1px solid #ccc;
    }
  }
  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-track {
    background-color: #ebebeb;
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: var(--mainColor);
  }
}
.declaration-footer {
  border-top: 0;
  .form-group {
    position: relative;
    padding: 10px 0 22px 0;
    input {
      width: 4px;
      height: 7px;
      visibility: hidden;
      position: absolute;
    }
  }
  .e-signature-feild {
    padding: 5px 10px;
    border-radius: 4px;
    max-width: 170px;
    width: 100%;
  }
  .form-group label {
    position: relative;
    cursor: pointer;
    font-size: 14px;
    color: #000;
    font-weight: 500;
  }

  .form-group label:before {
    content: "";
    content: "";
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #000000;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 6px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 8px;
    top: 0;
  }

  .form-group input:checked + label:after {
    content: "";
    display: block;
    position: absolute;
    top: 7px;
    left: 5px;
    width: 4px;
    height: 7px;
    border: solid #000000;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}
.agreement-body-modal {
  background: #f6f6f7;
  padding: 10px 20px;
  border-radius: 8px;

  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-track {
    background-color: #ebebeb;
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: var(--mainColor);
  }
}

.agreement-footer {
  .form-group {
    display: block;
    margin-bottom: 15px;
  }

  .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  .form-group label {
    position: relative;
    cursor: pointer;
    font-size: 14px;
    color: #000;
    font-weight: 500;
  }

  .form-group label:before {
    content: "";
    content: "";
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #000000;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 6px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 8px;
    top: -1px;
  }

  .form-group input:checked + label:after {
    content: "";
    display: block;
    position: absolute;
    top: 7px;
    left: 5px;
    width: 4px;
    height: 7px;
    border: solid #000000;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  .btn {
    border-radius: 5.731px;
    background: #d4d4d9;
    border-color: #d4d4d9;
    color: #000;
    padding: 7px 20px;
    font-weight: 600;
  }

  .btn-primary {
    background: var(--mainColor);
    border-color: var(--mainColor);
    color: #fff;
  }
}
.can-toggle {
  span.switcher {
    position: relative;
    display: block;
    width: 145px;
    height: 35px;
    border-radius: 5px;
    input {
      appearance: none;
      position: relative;
      width: 145px;
      height: 35px;
      border-radius: 5px;
      background-color: #fff;
      outline: none;
      border: 1px solid #a1a1a1;
      font-weight: 600;
      &:before,
      &:after {
        z-index: 2;
        position: absolute;
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
      }
      &:before {
        content: "Yes";
        left: 0;
      }
      &:after {
        content: "No";
        right: 0;
      }
    }
    label {
      z-index: 1;
      position: absolute;
      top: 0;
      bottom: 0;

      border-radius: 5px;
    }
    &.switcher-1 {
      input {
        // transition:.25s -.1s;
        &:checked {
          background-color: #fff;
          &:before {
            color: #fff;
          }
          &:after {
            color: #000;
          }
          & + label {
            left: 0;
            right: 72px;
            background: #16a070;

            // transition: left .5s, right .4s .2s;
          }
        }
        &:not(:checked) {
          background: #fff;
          //  transition: background .5s -.1s;
          &:before {
            color: #000;
          }
          &:after {
            color: #fff;
          }
          & + label {
            left: 72px;
            right: 0;
            background: #93939d;
            color: #fff;

            // transition: left .4s .2s, right .5s, background .35s -.1s;
          }
        }
      }
    }
  }
}

// .switch-field {
//   display: flex;
//   // margin-bottom: 36px;
//   overflow: hidden;
//   border: 1px solid #a1a1a1;
//   border-radius: 6px;
// }

.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.switch-field label {
  background-color: #fff;
  color: #000;
  font-size: 14px;
  line-height: 1;
  text-align: center;
  padding: 6px 19px;
  margin-right: 0px;
  transition: all 0.1s ease-in-out;
}

.switch-field label:hover {
  cursor: pointer;
}

.switch-field input:checked + label {
  background-color: #93939d;
  box-shadow: none;
  color: #fff;
  border-radius: 3px;
}

.switch-field label:first-of-type {
  border-radius: 6px 0 0 6px;
}

.switch-field label:last-of-type {
  border-radius: 0 6px 6px 0;
}
.gst-enroll-no {
  width: 170px;
  // width: 100%;
  white-space: nowrap;
  display: inline-flex;
  vertical-align: middle;
  justify-content: space-between;
  // text-overflow: ellipsis;
  // overflow: hidden;
  padding-right: 3px;
}
.gst-declation-input {
  border: 1px solid #bbb9b9;
  padding: 2px 10px;
  border-radius: 4px;
  display: inline-flex;
  margin: 5px 5px 7px;
  vertical-align: middle;
  justify-content: space-between;
  max-width: 170px;
  @media screen and (max-width: 767px) {
    width: 100%;
    margin: 5px 0;
  }
  img {
    max-width: 16px;
  }
  input {
    border: none;
    padding: 0;
    width: 100%;
    &:focus {
      outline: 0;
    }
  }
}
.ant-popover {
  z-index: 1050 !important; /* Set this to a value higher than the modal's z-index */
}
.GstInfoText {
  max-width: 400px;
  margin-bottom: 0;
  color: #000;
  font-size: 13px;
  position: relative;
  z-index: 99;
}
.cross-icon-btn {
  background: #fff;
  border: 1px solid #000;
  padding: 3px;
  border-radius: 18px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -8px;
  top: -10px;
  &:hover {
    border: 1px solid #000;
  }
  .cross-icon {
    max-width: 8px;
  }
}
.signature-save-btn {
  padding: 5px 10px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.signature-box {
  border: 1px solid #ccc;
  height: 90px;
  width: 245px;
  position: relative;
  border-radius: 5px;
  padding: 5px;
  .Loader .ant-spin-dot-item {
    background-color: #4ae5aa !important;
  }
  .Loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  canvas {
    width: 100% !important;
    height: 100% !important;
  }
 
}
.non-gst-declaration {

}