.KikoDashboardWrapper {
  .RightBlock {
    .addsinglecatelog {
      .catelogHeader {
        background: #fafafa;
        padding: 13px 20px;

        @media screen and (max-width: 767px) {
          padding: 20px;
        }

        .need-help-heading {
          display: flex;
          align-items: center;
          justify-content: space-between;

          @media screen and (max-width: 767px) {
            flex-wrap: wrap;
            margin-bottom: 10px;
          }
        }
      }

      h3 {
        color: #323237;
        @media screen and (max-width: 1536px) {
          font-size: 18px;
          margin: 0;
        }

        @media screen and (max-width: 767px) {
          margin-bottom: 10px;
        }

        img {
          margin-right: 13px;
          max-width: 8px;
          width: 100%;
        }
      }

      .catalog-steps {
        display: flex;
        counter-reset: li;
        list-style-type: none;
        gap: 16px;
        margin: 0;
        border-radius: 4px 4px 0 0;

        li.active {
          border-bottom: 3px solid var(--mainColor);

          &::before {
            background: var(--mainColor);
            color: #fff;
          }

          a {
            color: var(--mainColor);
          }
        }

        li {
          position: relative;
          padding: 0 0 0px 40px;
          line-height: 40px;
          cursor: pointer;

          &::before {
            content: counter(li);
            counter-increment: li;
            height: 32px;
            width: 32px;
            color: rgb(0, 0, 0);
            text-align: center;
            position: absolute;
            left: 0;
            top: 50%;
            background: #e2e2e5;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateY(-50%);
          }

          a {
            font-weight: 400;
            font-size: 14px;
            color: #27272b;
            text-decoration: none;
            height: 100%;
            display: block;
          }
        }
      }

      .rightLinks {
        text-align: right;

        a {
          display: block;
          text-decoration: none;
          margin-bottom: 15px;
          font-weight: 400;
          font-size: 16px;
          color: #404047;
          text-align: right;

          @media screen and (max-width: 1536px) {
            font-size: 14px;
          }

          @media screen and (max-width: 767px) {
            text-align: left;
            margin-bottom: 7px;
          }

          img {
            margin-right: 10px;
            max-width: 25px;
          }
        }
      }

      .tab-content {
        margin-top: 20px;
        padding: 0 28px;

        @media screen and (max-width: 767px) {
          margin-top: 0;
          padding: 20px;
        }

        .searchCategory {
          border: 1px solid #d4d4d9;
          border-radius: 4px;
          max-width: 360px;
          display: flex;
          align-items: center;
          padding: 8px 18px;
          margin-bottom: 21px;

          img {
            margin-right: 15px;
            max-width: 24px;
          }

          input {
            border: none;
            width: 100%;
            padding: 0;

            &:focus {
              outline: none;
            }
          }
        }

        .category-list {
          @media screen and (max-width: 992px) {
            margin-bottom: 25px;
          }

          @media screen and (max-width: 767px) {
            padding: 0;
          }

          p {
            margin: 0;
            font-weight: 600;
            font-size: 18px;
            color: #323237;
            margin-bottom: 7px;

            @media screen and (max-width: 1536px) {
              font-size: 14px;
            }
          }

          .category-cards {
            padding: 0;
            list-style: none;
            margin: 0;
            border: 1px solid #d4d4d9;
            overflow-y: scroll;
            max-height: 400px;

            li.active {
              background: var(--mainColor);
              color: #fff;
            }

            li {
              padding: 10px;
              font-weight: 400;
              font-size: 16px;
              color: #323237;
              border-bottom: 1px solid #d4d4d9;

              @media screen and (max-width: 1536px) {
                font-size: 13px;
              }

              &:last-child {
                border: none;
              }

              &:hover {
                background: var(--mainColor);
                color: #fff;
                cursor: pointer;
              }
            }
          }
        }

        .productImageBlock {
          border: 1px solid #d4d4d9;

          .profileHeader {
            background: #eae6f3;
            padding: 10px 18px;

            p {
              font-weight: 400;
              font-size: 15px;
              color: #444448;
              text-align: center;

              @media screen and (max-width: 1536px) {
                font-size: 13px;
              }
            }
          }

          .image-wrapper {
            padding: 16px;
            max-width: 265px;
            margin: 0 auto;

            .productimg {
              height: 192px;
              width: 100%;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            button {
              .icons {
                max-width: 20px;
                margin-right: 5px;
                height: 20px;

                @media screen and (max-width: 1536px) {
                  max-width: 17px;
                  height: 17px;
                }
              }
            }

            p {
              color: #404047;
              margin: 9px 0 16px;
            }
          }
        }

        .product-guidelines {
          border: 1px solid #d4d4d9;
          border-radius: 4px;
          padding: 10px 10px 10px 25px;
        }

        .guidelines {
          padding: 10px 12px 0;

          ul {
            padding-left: 30px;
            margin-top: 25px;
            padding-right: 16px;

            h6 {
              font-weight: 500;
              font-size: 18px;
              color: #000000;

              @media screen and (max-width: 1536px) {
                font-size: 12px;
              }
            }

            li {
              color: #46464e;
              font-size: 16px;

              @media screen and (max-width: 1536px) {
                font-size: 14px;
              }
            }
          }
        }

        #menu1 {
          .add-variants-table {
            padding: 20px 0;
            h3 {
              color: #000 !important;
              margin: 0;
            }
            table {
              border: 1px solid #e1cfff;

              thead {
                td {
                  padding: 10px 15px;
                  background: #eae6f3;
                  font-size: 14px;
                  white-space: nowrap;
                }
              }
              tbody {
                td {
                  padding: 10px 15px;
                }
                input {
                  padding: 9px;
                  border: 1px solid #c0c0c7;
                  min-width: 80px;
                  color: #000;
                  font-size: 13px;
                  text-align: center;
                }
                select {
                  border: 1px solid #c0c0c7;
                  color: #000;
                  font-size: 13px;
                  padding: 9px;
                  min-width: 100px;
                  &:focus {
                    box-shadow: none !important;
                  }
                }
              }
              tfoot {
                td {
                  padding: 10px 15px;
                }
                .btn-primary {
                  color: #fff;
                  img {
                    max-width: 17px;
                    margin-right: 6px;
                    vertical-align: text-top;
                  }
                }
              }
           
            }
            .blue-box {
              display: flex;
              align-items: center;
              justify-content: center;
              input {
                background: #eae6f3;
                border: transparent;
                max-width: 75px;
                width: 100%;
                color: #300787;
                font-weight: 600;
                font-size: 18px;
                padding: 5px;
                text-align: center;
              }
              input::-webkit-outer-spin-button,
              input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
            }
          }
          .row {
            h5 {
              border-bottom: 1px solid #c0c0c7;
              padding-bottom: 8px;
              margin: 0;
            }
          }

          form {
        

            .min-text {
              @media screen and (max-width: 767px) {
                min-width: 90px;
              }
            }

            label {
              // max-width: 130px;
              width: 100%;
              font-size: 14px;
                font-weight: 600;

            }

            .col-sm-7 {
              @media screen and (max-width: 767px) {
                width: 100%;
              }
            }

            .form-control {
              border: 1px solid #93939d;
              border-radius: 4px;
              padding: 8px 4px;
              font-size: 14px;
              background: #fff;

              &:focus {
                box-shadow: none;
              }

              @media screen and (max-width: 1536px) {
                font-size: 11px;
              }
            }

            select {
              border: 1px solid #93939d;
              border-radius: 4px;
              padding: 8px 4px;
              width: 100%;
              font-size: 14px;
              // background: #fff;
              height: 39px;

              @media screen and (max-width: 1536px) {
                font-size: 11px;
                height: 34.5px;
              }

              &:focus {
                outline: none;
                box-shadow: none;
              }
            }

            .cancellable-section {
              display: flex;
              gap: 30px;
              align-items: center;
              margin-top: 20px;
              @media screen and (max-width:767px) {
                flex-wrap: wrap;
                gap: 15px;
              }

              form {
                padding: 0;
              }
            }

            .settlementAmount {
              // padding-bottom: 15px;

              .amount {
                border: 1px solid #9f9f9f;
                background: rgb(225 225 225 / 85%);
                color: #000;
                padding: 2px 7px;
                border-radius: 5px;
              }
            }
          }

          .uploadedImage {
            border: 1px solid #d4d4d9;
            border-radius: 4px;
            padding: 10px 10px 10px 25px;
            margin-bottom: 9px;

            h5 {
              color: #404047;
              border: none;
              margin: 0;
            }

            .front-back-uploaded {
              display: flex;
              flex-wrap: wrap;
              gap: 25px;
              margin: 10px 0;
            }
          }

          .footerbtn {
            display: flex;
            justify-content: center;
            gap: 10px;
            margin-top: 30px;
            @media screen and (max-width:767px) {
              margin-top: 10px;
            }
          }

          .uploadedImage {
            .change-text {
              margin-bottom: 2px;
              font-weight: 400;
              font-size: 14px;
              color: #46464e;
            }

            .change-image {
              background: none;
              border: none;
              position: relative;
              color: #300787;
              padding: 0;

              &:hover {
                text-decoration: underline;
              }

              input {
                opacity: 0;
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                padding: 0;
              }
            }

            .changeBtn {
              background: none;
              border: none;
              color: #300787;
              font-weight: 500;
            }
          }
        }
      }

      .search-category {
        margin-bottom: 20px;
      }
    }
  }
}

.add-image-btn {
  width: 100px;
  height: 82px;
  border: 1px dashed #59399f;
  border-radius: 4px;
  background: #eae6f3;
  font-size: 14px;
  font-weight: 400;
  color: #2c067b;
  position: relative;

  .add-images {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: 100%;
  }

  img {
    display: block;
    margin: 0 auto;
    max-width: 26px;
  }

  input {
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
    position: relative;
    cursor: pointer;
    padding: 0;
  }
}

.guidelinesHeading {
  border: 1px solid #fbae17;
  border-radius: 0;
  background: rgb(251 174 23 / 8%);
  padding: 8px 10px;
  text-align: center;
  display: flex;
  align-items: center;
  // margin-bottom: 20px;

  p {
    text-align: left;
    margin-left: 5px;
    margin-bottom: 0;

    @media screen and (max-width: 1536px) {
      font-size: 13px;
    }
  }

  img {
    max-width: 24px;
    margin-right: 6px;

    @media screen and (max-width: 1536px) {
      max-width: 15px;
    }
  }
}

.images-block-flex {
  margin: 10px 0 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.images-block {
  margin: 10px 0 8px;
}

.image-preview-Block {
  position: relative;

  .img-preview {
    width: 100px;
    height: 82px;

    .aad-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
    }
  }

  .cross-icon {
    position: absolute;
    right: -6px;
    top: -6px;

    img {
      width: 20px;
      height: 20px;
    }
  }
}

.images-catalog {
  height: 170px;
  overflow-y: scroll;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 10px;

  @media screen and (max-width: 991px) {
    margin-bottom: 30px;
  }
}

.url-text {
  border: 1px solid #93939d;
  border-radius: 4px;
  padding: 7px;
  max-width: 250px;
  width: 100%;
}

.not-allowed {
  font-size: 18px;
  font-weight: 400;
  color: #fd2f2f;
  margin-bottom: 20px;

  @media screen and (max-width: 1536px) {
    font-size: 14px;
  }

  // @media screen and (max-width:991px) {
  //   margin-top: 30px;
  // }
}

.not-allowed img {
  max-width: 21px;
  margin-right: 10px;
  vertical-align: middle;

  @media screen and (max-width: 1536px) {
    max-width: 16px;
  }
}

.Notallowed-images {
  .image-title {
    font-size: 12px;
    font-weight: 400;
    color: #636363;
    margin-bottom: 7px;
  }

  .not-allowed-image {
    font-size: 10px;
    font-weight: 400;
    color: #8d8d8d;
    text-transform: uppercase;
    margin: 0;
    display: flex;
    align-items: center;

    img {
      max-width: 14px;
      margin-right: 5px;
    }
  }

  .product-image {
    max-width: 47px;
    height: 47px;
    width: 100%;

    img {
      max-width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }
  }
}

.modal_footer {
  display: flex;
  justify-content: end;
  gap: 10px;
  padding: 18px 0 0 0;

  .continue-btn {
    background: var(--mainColor);
    border-radius: 4px;
    color: #fff;
    border: 1px solid var(--mainColor);
    padding: 4px 15px;
  }

  .cancle-btn {
    border: 1px solid #000000;
    color: #000000;
    background: #fff;
  }
}

.modal_body {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 15px 0;

  h4 {
    @media screen and (max-width: 1536px) {
      font-size: 14px;
    }
  }
}

.modal-heading {
  h3 {
    margin-bottom: 15px;

    @media screen and (max-width: 1536px) {
      font-size: 15px;
    }
  }
}

.Returnable {
  position: relative;

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: 0;
    width: 18px;
    height: 18px;

    opacity: 0;
  }

  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #323237;
    font-size: 16px;
    font-weight: 600;
  }

  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
  }

  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: "";
    width: 18px;
    height: 18px;
    background: #1cce90;
    border: 1px solid #59595f;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.Cancellable {
  position: relative;

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: 0;
    width: 18px;
    height: 18px;
    opacity: 0;

    @media screen and (max-width: 1536px) {
      width: 15px;
      height: 15px;
    }
  }

  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #323237;
    font-size: 16px;
    font-weight: 600;
  }

  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
  }

  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: "";
    width: 18px;
    height: 18px;
    background: #1cce90;
    border: 1px solid #59595f;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.submit-catalog-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  max-width: 500px;
  width: 100%;
  height: 500px;
  padding: 15px;
  box-sizing: border-box;
}
.submit-catalog-modal {
  background: rgb(255, 255, 255);
  border-radius: 18.1782px;
  border: 0.720565px solid #b4b4bd;
  max-width: 100%;
  width: 100%;
  height: 100%;
  position: relative;

  .submit-catalog {
    .backgorund-img {
      width: 100%;
      height: 305px;
      background: linear-gradient(129.02deg, #59399f -14.67%, #acacdf 138.96%);
      border-radius: 18.1782px 18.1782px 0 0;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .submitwhite-bg {
      width: 100%;
      bottom: 0;
      position: absolute;
      z-index: 0;
      height: 50%;
      @media screen and (max-width:767px) {
        height: 60%;
      }
    }

    .submit-catalog-content {
      padding: 30px 20px;
      text-align: center;
      z-index: 1;
      position: absolute;
      bottom: 0;
   .submit-catalog-btn {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    margin-top: 20px;
    @media screen and (max-width:767px) {
      flex-wrap: wrap;
    }
   }
      h3 {
        color: #000000;
      }

      p {
        color: #000000;
        max-width: 500px;
        margin: 0 auto;
      }
    }
  }
}

.label-text {
  margin: 0;
  font-size: 14px;
  color: #000;
  white-space: nowrap;

  @media screen and (max-width: 1536px) {
    font-size: 14px;
  }
}

.price-note {
  color: #ed1846;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 7px;
}

.PlatformPopover {
  font-size: 14px;
  color: #000;
  margin-left: 25px;
  margin-bottom: 0;
  display: inline-block;

  img {
    cursor: pointer;
  }
}
.PlatPopover {
  font-size: 14px;
  color: #000;
  background: #e6f0ff;
  padding: 7px 10px;

  img {
    cursor: pointer;
    max-width: 17px;
    vertical-align: text-top;
  }
}
.descriptionBlock {
  display: flex;
  flex-direction: column;
  // padding: 0;

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    gap: 5px;
  }
}

.upload-image-section {
  ul {
    li {
      color: #3384ff;
      font-weight: 400;
      font-size: 13px;
    }
  }

  .upload-images {
    display: flex;
    align-items: center;
    // justify-content: end;
    gap: 48px;
    margin-bottom: 35px;

    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
      gap: 10px;
      justify-content: center;
    }

    h6 {
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      color: #323237;
    }

    p {
      font-weight: 500;
      font-size: 14px;
      color: #323237;
      text-align: center;
      margin: 0;
    }

    .sample-images {
      display: flex;
      align-items: center;
      gap: 15px;

      .imagesPreview {
        max-width: 140px;
        border-radius: 4px;
        width: 100%;
        background: #fff;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        height: 110px;
        padding: 7px;

        img {
          max-width: 100%;
          width: 100%;
          height: 100%;
          border-radius: 4px;
          object-fit: contain;
        }
      }
    }

    .uploaded-images {
      display: flex;
      align-items: center;
      gap: 15px;

      .uploadImg {
        border: 1px solid #8ab8ff;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        max-width: 100%;
        height: 110px;
        width: 140px;
        display: flex;
        align-items: center;

        .add-images-preview {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 4px;
        }

        button {
          border: 1px dashed #59399f;
          border-radius: 4px;
          background: #eae6f3;
          font-size: 14px;
          font-weight: 400;
          color: #2c067b;
          position: relative;
          margin: 15px;

          input {
            width: 100%;
            height: 100%;
            opacity: 0;
            z-index: 1;
            position: relative;
            cursor: pointer;
            padding: 0;
          }

          .add-images {
            position: absolute;
            transform: translate(-50%, -50%);
            left: 50%;
            top: 50%;
            width: 100%;
          }
        }
      }
    }
  }
}
.GSTinfoText {
  max-width: 300px; 
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    font-size: 14px;
    font-weight: 400;
    color: #000;
  }
}
.platformContent {
  max-width: 350px;

  li {
    font-size: 14px;
    font-weight: 400;
    color: #000;
  }

  span {
    color: #1fe29e;
  }
}
.Insufficient-modal {
  h4 {
    font-weight: 600;
    color: #000;
  }
  p {
    font-size: 18px;
    color: #767676;
    text-align: center;
    font-weight: 500;
    line-height: normal;
    margin: 8px 0;
  }
  .wallet-link {
    text-align: center;
    color: #673ab7;
    font-weight: 500;
    display: flex;
    justify-content: center;
  }
}
.barcode-scanner {
  border: none;
  padding: 0;
}
.react-responsive-modal-closeButton {
  display: none;
}
.addsinglecatelog {
  .accordion-button:not(.collapsed) {
    background: none;
    border: none;
    border-bottom: 1px solid #ccc;
    &:focus {
      box-shadow: none;
    }
  }
  .accordion {
    margin-bottom: 25px;
    @media screen and (max-width:767px) {
      margin-bottom: 10px;
    }
  }
  .accordion-item {
    border: none;
  }
  .accordion-body {
    padding: 15px 0 0 0;
  }
  .accordion-button {
    padding: 0 0 10px 0;
    box-shadow: none;
 
  }
  .form-label {
    font-weight: 500;
  }
  .accordion-button:not(.collapsed)::after {
    background-image:url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27none%27 stroke=%27%23212529%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27%3e%3cpath d=%27M2 5L8 11L14 5%27/%3e%3c/svg%3e");
  }
}
.flex-wrap {
  gap: 25px;
 @media screen and (max-width:767px) {
  flex-wrap: wrap;
  gap: 15px;
 }
}
.colordropdown .dropdown-toggle {
  border: 1px solid #93939d;
  border-radius: 4px;
  padding: 8px 4px;
  font-size: 14px;
  background: #fff;
  width: 100%;
  text-align: left;
  position: relative;
  @media screen and (max-width: 1536px)  {
    font-size: 11px;
  }
}
.colordropdown .dropdown-toggle::after {
  background: url(../../images/Inventry/down-arrow.svg) no-repeat;
  background-size: 12px;
  width: 12px;
  height: 12px;
  background-position: center;
  border: none;
  right: 11px;
  position: absolute;
  top: 12px;
}
.colordropdown .dropdown-menu.show {
  width: 100%;
  border: 1px solid #93939d;
    border-radius: 4px;
    overflow-y: auto;
    max-height: 295px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}
.dropdown-color-flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  font-size: 14px;
  text-align: center;
  padding: 15px;
}
.dropdown-color {
  width: 75px;
  height: 32px;
  border-radius: 4px;
  cursor: pointer;
  
}
.Red {
  background: #E60A0A;
}
.Yellow {
  background: #FFDE59; 
}
.Green {
  background: #7FDA57;
}
.Blue {
  background: #2088E7;
}
.Purple {
  background: #CC6CE7;
}
.Orange {
  background: #F69E0C; 
}
.Lime {
  background: #3EFE73;
}
.Aqua {
  background: #5CE1E6;
}
.Teal {
  background: #08929F;
}
.Black {
  background: #010101;
}
.Brown {
  background: #8B6F64; 
}
.Peach {
  background: #FFACA6;
}
.Maroon {
  background: #851815;
}
.Cyan {
  background: #19FFFF;
}
.NavyBlue {
  background: #0D476F;
}
.Pink {
  background: #F16192;
}
.skin-dropdown-list {
  border-bottom: 1px solid #93939d;
  padding: 7px 12px;
  font-size: 12px;
  cursor: pointer;
  &:hover {
    background: #f1f1f1;
  }
  &:last-child {
    border-bottom: 0;
  }
}
.react-datepicker-wrapper {
  @media screen and (max-width:767px) {
    width: 100%;
  }
  input {
    @media screen and (max-width:767px) {
      width: 100%;
    }
  }
}

.filterBlock {
  span {
    @media screen and (max-width:767px) {
      width: 100%;
    }
  }
}
.custom-select .dropdown-heading {
  border: 1px solid #93939d !important;
    border-radius: 4px;
    padding: 8px 4px;
    font-size: 14px;
    background: #fff;
    padding-right: 50px !important;
    height: 34px !important;
}
.dropdown-heading {
  .dropdown-heading-value {
    font-size: 12px;
  }
}
.custom-select .dropdown-container {
  border: none !important;
}
.custom-select .gray {
  color: #000 !important;
}
.custom-select svg{
  height: 18px;
  width: 18px;
  position: absolute;
  right: 8px;
  stroke-width: 1px !important;
}

.custom-select {
.item-renderer  {
  align-items: center !important;
  input {
    width: 13px !important;
    height: 13px !important;
    margin-right: 10px !important;
  }
}
.select-item {
  font-size: 12px !important;
  padding: 7px 12px !important;
  color: #212529;
 }
 .clear-selected-button {
  position: absolute;
  right: 23px;
  top: 8px;

 } 
.dropdown-container {
  position: relative !important;
  &:focus-within {
    box-shadow: none !important;
    border-color:#ccc !important;
  }
}

}
