.KikoDashboardWrapper {
  .ProductPricingWrapper {
    h6 {
      color: #000000;

      @media screen and (max-width: 1536px) {
        font-size: 16px;
        margin: 0;
      }
    }

    .left-section {
      border-radius: 4px;
      padding: 0 20px 15px;

      @media screen and (max-width: 767px) {
        padding: 0 10px;
      }

      .catalog-steps {
        border: 1px solid #e2e2e5;
        display: flex;
        padding: 17px;
        counter-reset: li;
        list-style-type: none;
        gap: 16px;
        margin: 0;
        border-radius: 4px 4px 0 0;

        @media screen and (max-width: 1536px) {
          padding: 14px;
        }

        @media screen and (max-width: 767px) {
          flex-wrap: wrap;
        }

        li {
          position: relative;
          padding: 0 0 0px 40px;
          font-weight: 400;
          font-size: 16px;
          color: #27272b;

          @media screen and (max-width: 1536px) {
            font-size: 14px;
          }

          @media screen and (max-width: 767px) {
            margin-bottom: 8px;
          }

          &::before {
            content: counter(li);
            counter-increment: li;
            height: 32px;
            width: 32px;
            color: rgb(0, 0, 0);
            text-align: center;
            position: absolute;
            left: 0;
            top: 50%;
            background: #e2e2e5;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateY(-50%);
          }

          &:first-child {
            color: var(--mainColor);

            &::before {
              background: var(--mainColor);
              color: #fff;
            }
          }

          &:last-child {
            @media screen and (max-width: 767px) {
              margin: 0;
            }
          }
        }
      }

      .upload-catalog-wrapper {
        padding: 16px;
        border: 1px solid #e2e2e5;
        border-radius: 0 0 4px 4px;

        .upload-catalog {
          display: flex;
          gap: 90px;
          margin-top: 40px;

          @media screen and (max-width: 1536px) {
            margin-top: 20px;
          }

          @media screen and (max-width: 992px) {
            gap: 50px;
            margin-top: 25px;
          }

          @media screen and (max-width: 767px) {
            flex-wrap: wrap;
          }

          .upload-catalog-block {
            @media screen and (max-width: 767px) {
              width: 100%;
            }

            .btn {
              @media screen and (max-width: 767px) {
                width: 100%;
              }
            }

            p {
              font-weight: 500;
              font-size: 16px;
              color: #27272b;
              position: relative;
              padding-left: 16px;
              margin-bottom: 20px;

              @media screen and (max-width: 1536px) {
                font-size: 14px;
                margin-bottom: 13px;
              }

              &::before {
                position: absolute;
                content: "";
                border-left: 2px solid #300787;
                height: 27px;
                left: 0;

                @media screen and (max-width: 1536px) {
                  height: 22px;
                }
              }
            }

            .image-preview {
              max-width: 199px;
              width: 100%;
              height: 100px;
              object-fit: contain;
              border: 1px solid var(--mainColor);
              border-radius: 4px;

              @media screen and (max-width: 767px) {
                max-width: 100%;
              }

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                border-radius: 4px;
              }

              .bulk-catalog {
                object-fit: contain;
              }
            }

            .upload-catalog-list {
              list-style: none;
              padding: 0;
              margin: 15px 0;

              li {
                position: relative;
                padding-left: 35px;
                font-size: 18px;

                @media screen and (max-width: 1536px) {
                  font-size: 13px;
                  padding-left: 25px;
                }

                &:first-child {
                  margin-bottom: 10px;
                }

                &::before {
                  position: absolute;
                  content: "";
                  background: url(../../images/upload-catalog.svg) no-repeat;
                  width: 23px;
                  height: 23px;
                  left: 0;
                  background-size: 22px;

                  @media screen and (max-width: 1536px) {
                    width: 17px;
                    height: 17px;
                    background-size: 17px;
                  }
                }
              }
            }
          }
        }
      }

      .seprator {
        border: 1px solid #e2e2e5;
      }

      .footer-section {
        border: 1px solid #e2e2e5;
        margin-top: 20px;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;

        @media screen and (max-width:767px) {
          flex-wrap: wrap;
        }

        .Book-training {
          text-decoration: none;
          padding: 0 0 0 40px;
          position: relative;
          margin-bottom: 8px;

          @media screen and (max-width: 767px) {
            flex-wrap: wrap;
            gap: 8px;
          }

          &::before {
            content: "";
            position: absolute;
            background: url(../../images/ProductPricing/youtube-icon.svg) no-repeat;
            width: 27px;
            height: 24px;
            background-size: 27px;
            background-position: center;
            left: 8px;
            top: 1px;

            @media screen and (max-width: 1536px) {
              width: 20px;
              height: 20px;
              background-size: 20px;
            }

            span {
              color: #000;
              margin-right: 10px;
            }
          }
        }

        .left-block {
          padding: 12px 10px;
          width: 50%;

          @media screen and (max-width:767px) {
            width: 100%;
          }

          ul {
            li {
              margin-bottom: 8px;
            }

          }
        }

        // &::after {
        //   content: "";
        //   position: absolute;
        //   background: url(../../images/book-training.svg) no-repeat;
        //   width: 22px;
        //   height: 22px;
        //   background-size: 20px;
        //   background-position-y: center;
        //   right: 5px;
        // }

        a {
          font-weight: 600;
          font-size: 16px;
          color: #005298;
          margin: 0;
          text-decoration: underline;

          @media screen and (max-width: 1536px) {
            font-size: 14px;
          }
        }

        h6 {
          color: #323237;
          position: relative;
          display: inline-block;
        }
      }
    }

    .right-section {
      border: 1px solid #e2e2e5;
      border-radius: 4px;
      padding: 12px;
      height: 100%;

      p {
        color: #323237;
      }

      .useful-link-list {
        list-style: none;
        padding: 0;

        li {
          position: relative;
          padding-left: 35px;
          margin-bottom: 16px;

          &::before {
            content: "";
            position: absolute;
            background: url(../../images/ProductPricing/youtube-icon.svg) no-repeat;
            width: 25px;
            height: 25px;
            background-size: 25px;
            left: 0;
          }

          &:nth-child(2) {
            &::before {
              background: url(../../images/ProductPricing/commision.svg) no-repeat;
            }
          }

          &:last-child {
            &::before {
              background: url(../../images/ProductPricing/delivery-icon.svg) no-repeat;
            }
          }

          .useful-links {
            font-weight: 300;
            font-size: 14px;
            color: #404047;
            text-decoration: none;
            width: 100%;
            display: block;

            &::after {
              content: "";
              position: absolute;
              background: url(../../images/ProductPricing/blue-right-arrow.svg) no-repeat;
              width: 22px;
              height: 22px;
              background-size: 10px;
              background-position: center;
              right: 0;
            }
          }
        }
      }
    }
  }
}

.section-heading {
  margin-bottom: 15px;
}

.upload-catalog-block {
  position: relative;

  .uploadBtn {
    position: relative;
    width: 100%;
    min-height: 41px;

    input {
      opacity: 0;
      padding: 0;
      height: 100%;
      z-index: 8;
      position: relative;
      cursor: pointer;
      width: 100%;
      padding: 7px 10px;
      max-width: 150px;
    }

    // .upload-img {
    //   top: 50%;
    //   position: absolute;
    //   margin: 0 auto;
    //   transform: translate(-50%, -50%);
    //   left: 50%;
    //   white-space: nowrap;
    //   color: #fff;
    //   font-weight: 500;
    //   font-size: 16px;
    // }
  }
}

.barcodeModal {
  max-width: 466px;
  width: 100%;
}

.barcodeModal #html5qr-code-full-region {
  border: 0 !important;
}